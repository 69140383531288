*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 18px;
}

body {
	margin: 0;
	--color-text: #111;
	--color-bg: #F7F5F3;
	--color-link: #999;
	--color-link-hover: #000;
	--font-size-xlarge: 2rem;
	--font-size-large: 1.5rem;
	--font-size-medium: 1.25rem;
	--font-size-small: 1rem;
	--color-menu: #000;
	--color-menu-hover: #e93f33;
	color: var(--color-text);
	background-color: var(--color-bg);
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

a {
	text-decoration: underline;
	color: var(--color-link);
	outline: none;
}

a:hover {
	text-decoration: none;
	color: var(--color-link-hover);
	outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	cursor: pointer;
	font: inherit;
}

.unbutton:focus {
	outline: none;
}

main {
	padding: 1.5rem;
	display: grid;
	height: 100vh;
	align-content: space-between;
	grid-gap: 0.5rem;
	grid-template-columns: 1fr auto;
	grid-template-areas: 'frame menu' 
						'heading heading' 
						'content content'
						'content content'
						'deco deco'
						'footer footer';

}

.frame {
	grid-area: frame;
	color: var(--color-link);
	margin-bottom: 1rem;
}

.frame__title {
	font-size: 1.25rem;
	margin: 0;
	font-weight: normal;
}

.frame__links {
	display: block;
}

.frame__links a:not(:last-child) {
	margin-right: 0.5rem;
}

.line {
	transform-origin: 0% 50%;
	padding-bottom: 0.35rem;
	white-space: nowrap;
	will-change: transform;
}

.oh {
	overflow: hidden;
}

.oh__inner {
	display: inline-block;
	transform-origin: 0% 50%;
	will-change: transform;
}

.content {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: calc(100vh - 13rem);
	position: relative;
	justify-content: flex-start;
	align-items: center;
}

.heading {
	grid-area: heading;
	font-size: var(--font-size-large);
	margin: 0 0 2rem 0;
	font-weight: normal;
	align-self: start;
	line-height: 1.4;
}

.heading strong {
	font-weight: 600;
}

.content-primary {
	grid-area: content;
	line-height: 1;
	font-size: var(--font-size-large);
}

.content-secondary {
	grid-area: footer;
	line-height: 1;
	margin: 0;
	align-self: end;
	font-size: var(--font-size-small);
	max-width: 400px;
}

.deco {
	grid-area: deco;
}

.deco__img-wrap {
	position: relative;
	overflow: hidden;
	width: 25vw;
	min-width: 250px;
	max-width: 400px;
	margin-bottom: 1rem;
	will-change: transform;
}

.deco__img-wrap--upper {
	height: 5vh;
}

.deco__img-wrap--lower {
	height: 25vh;
}

.deco__img {
	background: url(../img/1.jpg) no-repeat 50% 0%;
	width: 100%;
	height: 100%;
	will-change: transform;
}

.deco__img--lower {
	background-position: 50% -5vh;
}

.menu-link {
	align-self: start;
	justify-self: end;
	line-height: 1;
	grid-area: menu;
	overflow: hidden;
    position: relative;
    padding: 0 0 5px;
	font-size: var(--font-size-small);
}

.menu--open ~ .menu-link,
.state-details .menu-link {
	pointer-events: none;
}

.menu-link::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	height: 7%;
	width: 100%;
	background: currentColor;
	transition: transform 0.4s ease;
    transform-origin: 100% 50%;
}

.menu-link:hover::after,
.menu--open ~ .menu-link::after,
.state-details .menu-link::after {
	transform: scale3d(0,1,1);
	transform-origin: 0% 50%;
}

.menu {
	position: fixed;
	pointer-events: none;
	z-index: 100;
	opacity: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: grid;
	grid-template-columns: auto auto auto;
	align-content: center;
	justify-content: center;
	counter-reset: menucounter;
}

.menu--open {
	pointer-events: auto;
	opacity: 1;
}

.state-details .menu {
	pointer-events: none;
}

.menu__item {
	grid-column: 2;
	line-height: 4;
	display: block;
	position: relative;
}

.menu__item-link {
	text-decoration: none;
	line-height: 1.25;
	/*margin-top: calc( -0.15 * var(--font-size-xlarge));*/
	font-size: var(--font-size-xlarge);
	cursor: pointer;
	position: relative;
	padding: 0 calc( 0.45 * var(--font-size-xlarge));
	color: var(--color-menu);
}

.menu__item-link:hover {
	color: var(--color-menu-hover);
}

.menu__item-link::before {
	position: absolute;
	left: 0;
	top: 18%;
	text-align: right;
	counter-increment: menucounter;
	content: counters(menucounter, ".", decimal-leading-zero);
	opacity: 0;
	line-height: 1;
	padding-right: 0.5rem;
	display: grid;
	place-items: center;
	font-size: var(--font-size-small);
	color: var(--color-text);
	transform: scale3d(0,0,0);
}

.menu__item-link:hover::before {
	opacity: 1;
	transform: scale3d(1,1,1);
}

.close {
	position: absolute;
	top: 1rem;
	right: 1.5rem;
	font-size: 3rem;
	line-height: 1;
	opacity: 0;
	pointer-events: none;
}

.close:hover {
	color: var(--color-menu);
}

.menu--open .close {
	opacity: 1;
	pointer-events: auto;
}

.state-details .close {
	pointer-events: none;
}

.details-wrap,
.details {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;
}

.details-wrap {
	overflow: hidden;
}

.panel {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    display: grid;
    grid-template-columns: repeat(50, 2%);
    grid-template-rows: repeat(50, 2%);
    --grid-row: 1;
    --grid-column: 1;
    transform: rotate3d(0,0,1,-35deg);
    opacity: 0.5;
}

.panel__img {
    grid-area: var(--grid-row) / var(--grid-column) / span 12 / span 5;
	will-change: transform;
}

.pos-1 { --grid-row: 10; --grid-column: 1; }
.pos-2 { --grid-row: 1; --grid-column: 18; }
.pos-3 { --grid-row: 1; --grid-column: 29; }
.pos-4 { --grid-row: 15; --grid-column: 12; }
.pos-5 { --grid-row: 17; --grid-column: 25; }
.pos-6 { --grid-row: 20; --grid-column: 43; }
.pos-7 { --grid-row: 35; --grid-column: 5; }
.pos-8 { --grid-row: 40; --grid-column: 14; }
.pos-9 { --grid-row: 37; --grid-column: 29; }
.pos-10 { --grid-row: 35; --grid-column: 42; }
.pos-11 { --grid-row: 7; --grid-column: 46; }
.pos-12 { --grid-row: 32; --grid-column: 20; }
.pos-13 { --grid-row: 22; --grid-column: 2; }
.pos-14 { --grid-row: 9; --grid-column: 38; }
.pos-15 { --grid-row: 4; --grid-column: 7; }
.pos-16 { --grid-row: 28; --grid-column: 36; }

.panel__img {
	width: 100px;
}

.details {
	display: grid;
	place-items: center;
	pointer-events: none;
	opacity: 0;
}

.details--open {
	pointer-events: auto;
	opacity: 1;
}

.details--open ~ .close--details {
	opacity: 1;
	pointer-events: auto;
}

.details__content {
	padding: 1.5rem;
	margin: 0 auto;
	position: relative;
}

.details__content-title {
	font-weight: normal;
	margin: 0;
	font-size: var(--font-size-xlarge);
}

.details__content-text {
	line-height: 1;
	max-width: 640px;
	font-size: var(--font-size-medium);
}

.details__content-link {
	position: relative;
	display: inline-block;
	font-size: var(--font-size-small);
	color: var(--color-text);
	cursor: pointer;
	text-decoration: none;
	font-weight: 600;
}

.details__content-link:hover {
	color: var(--color-menu-hover);
}

@media screen and (min-width: 53em) {
	body {
		overflow: hidden;
		--font-size-xlarge: 11vh;
		--font-size-large: 3.5vw;
		--font-size-medium: 2.75vw;
		--font-size-small: 1.65vw;
	}
	main {
		grid-gap: 0;
		height: 100vh;
		grid-template-columns: 45% 1fr;
		grid-template-areas: 'heading menu' 
							'content ...'
							'content deco'
							'... deco'
							'footer deco'
							'footer frame';
	
	}
	.line {
		padding-bottom: 0.5vw;
	}
	.frame {
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
	}
	.frame__links {
		margin-left: 2rem;
		display: inline-block;
	}
	.deco {
		align-self: end;
		justify-self: end;
	}
	.content {
		height: 100vh;
		justify-content: center;
	}
	.details__content {
		width: 50%;
		padding: 0.5rem;
	}
}
